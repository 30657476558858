import {AccountActionsTypes} from "../../Account/interface/account";
import * as AppStorage from "../../App/helpers/storage";
import {AlertActionsTypes} from "../../App/interface/alert";
import {ILoginState} from "../interfaces/login";
import AuthServices from "../services/auth";
import {MapActionsTypes} from "../../Map/interface/map";

export const AuthActions = {
    login: (data: ILoginState) => (dispatch: any) => new Promise((resolve, reject) => AuthServices.login(data).then(
        response => {
            AppStorage.set('token', response.data.token)
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Авторизация",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    logout: () => (dispatch: any) => new Promise((resolve, reject) => AuthServices.logout().then(
        response => {
            AppStorage.clear()
            dispatch({type: AccountActionsTypes.FETCH_ACCOUNT, payload: null})
            dispatch({type: MapActionsTypes.SET_MAP, payload: null})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Авторизация",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
};