import {IQr} from "../../Qr/interface/qr";

declare var ymaps: any;

export const getQrCode = (qr: IQr) => {
    return new ymaps.Placemark([qr.latitude, qr.longitude], {
            hintContent: 'QR: ' + qr.name,
            iconContent: "Щелкни по мне правой кнопкой мыши!",
            qrId: qr.id
        }, {
            preset: "islands#redStretchyIcon",
            iconLayout: 'default#image',
            iconImageHref: '/assets/images/QR.png',
            iconImageSize: [20, 20],
            iconImageOffset: [-10, -10],
            id: qr.id,
            type: 'QR'
        }
    );
}