import React, {Suspense} from "react";
import {useRoutes} from "react-router";
import AdminPage from "../Admin/AdminPage";
import Login from "../Auth/Login";
import Logout from "../Auth/Logout";
import MapPage from "../Map/MapPage";
import {Navigate} from "react-router-dom";
import {NotFoundPage} from "./components/NotFoundPage";

interface AuthProps {
	child: JSX.Element,
}

export default function Routes(): JSX.Element {

	const Auth = (props: AuthProps) => {
		const token = localStorage.getItem('token');

		return token ? props.child : <Navigate to="/login"/>;
	}


	const routing = useRoutes([
		{path: '/', element: <Suspense fallback={<div className="loading">Загружается...</div>}><MapPage/></Suspense>,},
		{path: '/admin', element: <Auth child={<Suspense fallback={<div className="loading">Загружается...</div>}><AdminPage/></Suspense>}/>},
		{path: '/login', element: <Login/>,},
		{path: '/logout', element: <Logout/>},
		{path: '*', element: <NotFoundPage/>}
	]);

	return <React.Fragment>{routing}</React.Fragment>;
}
