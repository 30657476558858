import {HttpService} from '../../App/services/http';
import {IDepartmentInput} from "../interface/department";

export const DepartmentServices = {
    departments: () => HttpService.get('/departments?limit=100'),
    department: (id: number) => HttpService.get(`/departments/${id}`),
    create: (data: IDepartmentInput) => HttpService.put('/departments', data),
    upsert: (data: IDepartmentInput[]) => HttpService.put('/departments/upsert', {departments: data}),
    update: (id: number, data: IDepartmentInput) => HttpService.post(`/departments/${id}`, data),
    delete: (id: number) => HttpService.delete(`/departments/${id}`),
};

export default DepartmentServices;