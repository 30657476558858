import React, {useEffect} from 'react';
import {Button, Grid, InputAdornment, styled, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, IRootState} from "../../../App/reducers/store";
import {useFormik} from "formik";
import {MapActionsTypes} from "../../../Map/interface/map";
import {getEntry} from "../../../App/data/map";
import {EntriesActionsTypes, IEntryInput} from "../../../Entry/interface/entry";
import {EntriesActions} from "../../../Entry/actions/entry";
import SearchIcon from "@mui/icons-material/Search";
import {IBuilding} from "../../../Buildings/interfaces/building";
import {StyledAutocomplete} from "../../../App/components/Select/Autocomplete";

const CustomForm = styled('form')({
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '10px',
    width: '100%'
});

const Item = styled(Grid)(() => ({
    marginTop: '15px',
    width: "100%",
}))

export const Entry = () => {
    const dispatch: Dispatch = useDispatch();
    const {drawingEntry, placemark} = useSelector((state: IRootState) => state.entries);
    const {buildings} = useSelector((state: IRootState) => state.buildings);
    const {event, map} = useSelector((state: IRootState) => state.map);

    useEffect(() => {
        if (event && drawingEntry) {
            const coordinates = event.get('coords');
            map.geoObjects.add(getEntry({
                ...drawingEntry,
                latitude: coordinates[0],
                longitude: coordinates[1]
            }));
        }
    }, [dispatch, map, event, drawingEntry]);

    const initialValues = {
        building: buildings.find((item: IBuilding) => item.id === drawingEntry?.building_id),
        latitude: drawingEntry?.latitude,
        longitude: drawingEntry?.longitude,
    }

    useEffect(() => {
        if (drawingEntry) {
            formik.setValues(initialValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingEntry]);

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (values) => {
            if (drawingEntry && values.building) {
                if (event) {
                    const coordinates = event.get('coords');
                    const data: IEntryInput = {
                        building_id: values.building.id,
                        latitude: coordinates[0],
                        longitude: coordinates[1]
                    }
                    dispatch(EntriesActions.create(data, map)).then(
                        () => dispatch({
                            type: EntriesActionsTypes.COMPLETE_ADD_ENTRY
                        }),
                        () => {
                        },
                    )
                } else if (placemark) {
                    placemark.editor.events.add("drawingstop", function (e: any) {
                        if (formik.values.building) {
                            const coordinates = e.get("target").geometry.getCoordinates();

                            const data: IEntryInput = {
                                building_id: formik.values.building.id,
                                latitude: coordinates[0],
                                longitude: coordinates[1]
                            }
                            dispatch(EntriesActions.update(drawingEntry.id, data)).then(
                                () => dispatch({
                                    type: EntriesActionsTypes.COMPLETE_EDIT_ENTRY
                                }),
                                () => {
                                },
                            )
                        }
                    });
                    placemark.editor.stopDrawing();
                }
                dispatch({
                    type: MapActionsTypes.SET_DEFAULT_MODE
                })
            }

        },
    });

    const deleteOnClick = () => {
        if (drawingEntry) {
            dispatch(EntriesActions.delete(drawingEntry.id, map)).then(
                () => {
                },
                () => {
                },
            )
        }
    }

    return <CustomForm onSubmit={formik.handleSubmit}>
        <Typography>Вход в здание</Typography>
        <Grid>
            <StyledAutocomplete
                fullWidth
                disablePortal
                size="small"
                disableClearable
                id="building_id"
                options={buildings}
                value={formik.values.building}
                getOptionLabel={(option: IBuilding | any) => option.name}
                onBlur={formik.handleBlur}
                onChange={(e: any, value: IBuilding | any) => formik.setFieldValue("building", value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        required
                        size="small"
                        placeholder="Введите название корпуса"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
            <Item display={'flex'} gap={2}>
                <Button
                    type="submit"
                    variant="contained"
                    disabled={!formik.isValid || !formik.dirty}
                >
                    Сохранить
                </Button>
                <Button
                    onClick={deleteOnClick}
                    variant="contained"
                    color="error"
                >
                    Удалить
                </Button>
            </Item>
        </Grid>
    </CustomForm>
}
