import {ITargetAction, ITargetsState, TargetActionsTypes} from "../interface/target";


const initialState: ITargetsState = {
	targetTo: [],
}

export const TargetReducer = (state: ITargetsState = initialState, action: ITargetAction): ITargetsState => {
	switch (action.type) {
		case TargetActionsTypes.SET_TARGET_TO:
			return {
				...state,
				...{
					targetTo: action.payload
				}
			}
		default:
			return state
	}
}
