import {HttpService} from '../../App/services/http';
import {IQRInput} from "../interface/qr";

export const QrServices = {
    qrs: () => HttpService.get('/qr?limit=100'),
    create: (data: IQRInput) => HttpService.put('/qr', data),
    update: (id: number, data: IQRInput) => HttpService.post(`/qr/${id}`, data),
    delete: (id: number) => HttpService.delete(`/qr/${id}`),
    print: (id: number) => HttpService.get(`/qr/${id}/image`, {}, true, true)
};

export default QrServices;