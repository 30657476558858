import {QrActionsTypes} from "../../Qr/interface/qr";
import {BuildingActionsTypes, IBuilding} from "../../Buildings/interfaces/building";
import {Dispatch} from "../reducers/store";
import {MapActionsTypes, MapModes} from "../../Map/interface/map";

declare var ymaps: any;

export const attachBuildingsListeners = (building: IBuilding, polygon: any, dispatch: Dispatch) => {
    const stateMonitor = new ymaps.Monitor(polygon.editor.state);

    stateMonitor.add("drawing", function (newValue: any) {
        polygon.options.set("strokeColor", newValue ? '#FF0000' : '#0000FF');
    });

    polygon.events.add('click', () => {
        dispatch({
            type: QrActionsTypes.COMPLETE_EDIT_QR
        })
        dispatch({
            type: MapActionsTypes.CHANGE_MODE, payload: MapModes.EDIT_MODE
        })
        dispatch({
            type: BuildingActionsTypes.EDIT_BUILDING,
            payload: {
                building: building,
                polygon: polygon
            }
        })
    });
}