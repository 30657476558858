import Actions from "../../App/interface/actions";
import {MapModes} from "../../Map/interface/map";
import {EntriesActionsTypes, IEntry, IEntryState} from "../interface/entry";
import {getEntry} from "../../App/data/map";
import {attachEntriesListeners} from "../../App/helpers/entry";
import {deleteItem} from "../../App/helpers/map";

const initialState: IEntryState = {
    entries: [],
    drawingEntry: null,
    placemark: null
}

export const EntriesReducer = (state: IEntryState = initialState, action: Actions): IEntryState => {
    switch (action.type) {
        case EntriesActionsTypes.FETCH_ENTRIES:
            return {
                ...state,
                ...{
                    entries: action.payload.data
                }
            };
        case EntriesActionsTypes.EDIT_ENTRY:
            if (!state.placemark) {
                action.payload.placemark.editor.startDrawing();
                return {
                    ...state,
                    ...{
                        drawingEntry: action.payload.entry,
                        placemark: action.payload.placemark
                    }
                };
            }
            return state;
        case EntriesActionsTypes.COMPLETE_EDIT_ENTRY:
        case EntriesActionsTypes.COMPLETE_ADD_ENTRY:
            if (state.placemark) {
                state.placemark.editor.stopDrawing();
            }
            return {
                ...state,
                ...{
                    drawingEntry: null,
                    placemark: null,
                    event: null
                }
            };
        case MapModes.ADD_ENTRY:
            return {
                ...state,
                ...{
                    drawingEntry: {
                        id: -1,
                        building_id: 0,
                        latitude: 0,
                        longitude: 0,
                    }
                }
            }
        case EntriesActionsTypes.CREATE_ENTRY:
            if (action.payload.map) {
                deleteItem(action.payload.map, 'entryId' , -1);
                const newPlacemark = getEntry(action.payload.entry);
                action.payload.map.geoObjects.add(newPlacemark);
                attachEntriesListeners(action.payload.entry, newPlacemark, action.payload.dispatch)
            }
            return {
                ...state,
                ...{
                    entries: state.entries.concat(action.payload.entry)
                }
            };
        case EntriesActionsTypes.DELETE_ENTRY:
            if (action.payload.map) {
                deleteItem(action.payload.map, 'entryId' , action.payload.id);
            }
            return {
                ...state,
                ...{
                    entries: state.entries.filter((entry: IEntry) => entry.id !== action.payload.id),
                    drawingEntry: null,
                    placemark: null
                }
            };
        default:
            return state
    }
}
