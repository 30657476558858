import {applyMiddleware, combineReducers, createStore} from 'redux';
import logger from "redux-logger";
import thunk, {ThunkDispatch} from "redux-thunk";
import {IMapState} from "../../Map/interface/map";
import {MapReducer} from "../../Map/reducers/map";
import {IQrState} from "../../Qr/interface/qr";
import {QrReducer} from "../../Qr/reducers/qr";
import {ITargetsState} from "../../Target/interface/target";
import {TargetReducer} from "../../Target/reducers/target";
import {IAlertsState} from "../interface/alert";
import {ILoadingState} from "../interface/loading";
import {AlertReducer} from "./alert";
import {LoadingReducer} from "./loading";
import {BuildingReducer} from '../../Buildings/reducers/building';
import {IBuildingState} from '../../Buildings/interfaces/building';
import {IEntryState} from "../../Entry/interface/entry";
import {EntriesReducer} from "../../Entry/reducers/entry";
import {DepartmentReducer} from "../../Departments/reducers/department";
import {IDepartmentState} from "../../Departments/interface/department";

const rootReducer = combineReducers<IRootState>({
    departments: DepartmentReducer,
    buildings: BuildingReducer,
    loading: LoadingReducer,
    entries: EntriesReducer,
    target: TargetReducer,
    alerts: AlertReducer,
    map: MapReducer,
    qr: QrReducer
});

export interface IRootState {
    departments: IDepartmentState,
    buildings: IBuildingState,
    loading: ILoadingState,
    target: ITargetsState,
    alerts: IAlertsState,
    entries: IEntryState,
    map: IMapState,
    qr: IQrState
}

export type RootState = ReturnType<typeof rootReducer>;
type AppAction = ReturnType<typeof store.dispatch>;

export const store = createStore(
    rootReducer,
    applyMiddleware(thunk, logger),
);

export type Dispatch = ThunkDispatch<RootState, never, AppAction>;
