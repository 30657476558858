import React from 'react'

interface PageFrameProps {
  children: React.ReactNode;
  className?: string; 
}

export const PageFrame: React.FC<PageFrameProps> = ({children, className}) => {
  return (
    <div className={className}>
      {children}
    </div>
  )
}
