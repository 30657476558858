import {IQr} from "../../Qr/interface/qr";
import {getQrCode} from "../helpers/getQrCode";
import {Dispatch} from "../reducers/store";
import {attachQrListeners} from "../helpers/qr";

const controlOptions = {
    multiRoute: true,
    allowSwitch: false,
    reverseGeocoding: true,
    mapStateAutoApply: true,
    routingMode: 'pedestrian',
    types: {masstransit: false, pedestrian: true, taxi: false},
};

const buildQr = (
    map: any,
    qrs: IQr[],
    dispatch: Dispatch,
    isAdmin: boolean
) => {
    qrs.forEach((qr: IQr) => {
        const placemark = getQrCode(qr);
        map.geoObjects.add(placemark);
        if (isAdmin) {
            attachQrListeners(qr, placemark, dispatch);
        }
    });

    if (!isAdmin) { map.controls.get('routePanelControl').routePanel.options.set(controlOptions)};

};

export {controlOptions, buildQr};
