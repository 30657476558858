import {IMapState, MapActionsTypes, MapModes} from "../interface/map";
import Actions from "../../App/interface/actions";

const initialState: IMapState = {
    map: null,
    mode: MapModes.DEFAULT_MODE,
    event: null,
    drawEvent: null
}

export const MapReducer = (state: IMapState = initialState, action: Actions): IMapState => {
    switch (action.type) {
        case MapActionsTypes.SET_MAP:
            return {
                ...state,
                ...{
                    map: action.payload
                }
            }
        case MapActionsTypes.CHANGE_MODE:
            return {
                ...state,
                ...{
                    mode: action.payload
                }
            }
        case MapActionsTypes.SET_DEFAULT_MODE:
            return {
                ...state,
                ...{
                    event: null,
                    mode: MapModes.DEFAULT_MODE
                }
            }
        case MapActionsTypes.HANDLE_CLICK_MODE:
            if (!state.event) {
                switch (state.mode) {
                    case MapModes.ADD_QR_CODES:
                    case MapModes.ADD_ENTRY:
                        return {
                            ...state,
                            ...{
                                event: action.payload,
                            }
                        }
                }
            }
            return {
                ...state,
                ...{
                    drawEvent: action.payload,
                }
            }
        default:
            return state
    }
}
