import {IQr, QrActionsTypes} from "../../Qr/interface/qr";
import {Dispatch} from "../reducers/store";
import {MapActionsTypes, MapModes} from "../../Map/interface/map";
import {BuildingActionsTypes} from "../../Buildings/interfaces/building";

declare var ymaps: any;

export const attachQrListeners = (qr: IQr, placemark: any, dispatch: Dispatch) => {
    const stateMonitor = new ymaps.Monitor(placemark.editor.state);

    stateMonitor.add("drawing", function (newValue: any) {
        placemark.options.set("strokeColor", newValue ? '#FF0000' : '#0000FF');
    });

    placemark.events.add('click', () => {
        dispatch({
            type: MapActionsTypes.CHANGE_MODE,
            payload: MapModes.EDIT_MODE
        })
        dispatch({
            type: BuildingActionsTypes.COMPLETE_EDIT_BUILDING
        })
        dispatch({
            type: QrActionsTypes.EDIT_QR,
            payload: {
                qr,
                placemark,
            }
        })
    });
}