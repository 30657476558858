import {useEffect} from 'react'
import {useSelector} from 'react-redux';
import {IRootState} from '../../App/reducers/store';
import {findNearestPoint} from '../../App/helpers/findNearestPoint';
declare var ymaps: any;

const useRoutePanelControl = (isAdmin: boolean) => {

  const { map } = useSelector((state : IRootState) => state.map);
  const { targetTo } = useSelector((state : IRootState) => state.target);

  const urlParams = new URLSearchParams(window.location.search);
  const targetFromUrl = urlParams.get('from')

  useEffect(() => {
    if (map && !isAdmin) {
      const control = map.controls.get('routePanelControl');
      const targetFrom = targetFromUrl?.length ? targetFromUrl.split(',').map(parseFloat) : [55.682286, 37.758798];
      control.routePanel.state.set({
        fromEnabled: false,
        from: targetFrom,
        toEnabled: true,
        to: targetTo?.length && targetTo[0][0] !== 55.683542 ? findNearestPoint(targetFrom, targetTo) : '',
      });
      map.options.set({
        geocodeProvider: false,
      });

      if ((targetTo?.length && targetTo[0][0] === 55.683542) || (targetFrom[0] === 55.683542 && targetFrom[1] === 37.756661)) {
        ymaps.route(
          [
            {
              type: 'waiPoint',
              point: targetFrom,
            },
            {
              type: 'waiPoint',
              point: [55.682286, 37.758798]
            },
            {
              type: 'waiPoint',
              point: [55.682542, 37.757608]
            },
            {
              type: 'waiPoint',
              point: targetTo
            },
          ],
          {
            multiRoute: true,
            routingMode: 'pedestrian',
            mapStateAutoApply: true,
          }).then(
          function (route: any) {
            map.geoObjects.add(route);
          },
          function (error: Error) {
            console.log("Возникла ошибка: " + error.message);
          }
        )
      }
      else {
        map.geoObjects.each(function (geoObject: any) {
          if (geoObject instanceof ymaps.multiRouter.MultiRoute) {
            map.geoObjects.remove(geoObject);
          }
        });
      }

    }
  
  }, [map, targetFromUrl, targetTo, isAdmin]);

}

export default useRoutePanelControl;