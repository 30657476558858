import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IRootState} from "../App/reducers/store";
import {MapActionsTypes} from "../Map/interface/map";
import YandexMap from "../YandexMap/YandexMap";
import {EditPanel} from "./components/EditPanel";

const AdminPage: React.FC = () => {
  const dispatch = useDispatch();

  const { map } = useSelector((state : IRootState) => state.map);

  useEffect(() => {
    dispatch({type: MapActionsTypes.SET_MAP, payload: null})
  }, [dispatch]);

  return (
    <>
      <YandexMap isAdmin={true}/>
      {map && <EditPanel/>}
    </>
  );
};

export default AdminPage;
