import {ILoadingAction, ILoadingState, LoadingActionsTypes} from "../interface/loading";


const initialState: ILoadingState = {
	loading: false
}

export const LoadingReducer = (state: ILoadingState = initialState, action: ILoadingAction): ILoadingState => {
	switch (action.type) {
		case LoadingActionsTypes.LOADING:
			return {loading: action.payload}
		default:
			return state
	}
}
