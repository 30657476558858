export interface ITargetsState {
	targetTo: any;
}

export interface ITargetAction {
	type: string,
	payload: ITargetsState
}

export enum TargetActionsTypes {
	SET_TARGET_TO = 'SET_TARGET_TO'
}
