export interface IMapState {
    map: any | null;
    mode: any | null;
    event: any;
    drawEvent: any
}

export enum MapModes {
    ADD_BUILDING = 'ADD_BUILDING',
    ADD_ENTRY = 'ADD_ENTRY',
    ADD_QR_CODES = 'ADD_QR_CODES',
    DEFAULT_MODE = 'DEFAULT_MODE',
    EDIT_MODE = 'EDIT_MODE',
}

export enum MapActionsTypes {
    SET_MAP = 'SET_MAP',
    CHANGE_MODE = 'CHANGE_MODE',
    HANDLE_CLICK_MODE = 'HANDLE_CLICK_MODE',
    SET_DEFAULT_MODE = 'SET_DEFAULT_MODE',
}
