import {Dispatch} from 'react';
import {AlertActionsTypes} from '../../App/interface/alert';
import {LoadingActionsTypes} from '../../App/interface/loading';
import {BuildingActionsTypes, IBuildingInput} from '../interfaces/building';
import {BuildingServices} from '../services/building';
import {IBuildingDepartment} from "../../Departments/interface/department";
import {MapActionsTypes} from "../../Map/interface/map";

export const BuildingActions = {
    buildings: () => (dispatch: any) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.buildings().then(
            (response) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: BuildingActionsTypes.FETCH_BUILDINGS,
                    payload: response,
                });
                resolve(response);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    }),
    building: (id: number) => (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.building(id).then(
            (response) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: BuildingActionsTypes.FETCH_BUILDING,
                    payload: response.data,
                });
                resolve(response);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    }),
    create: (data: IBuildingInput, map: any) => (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.create(data).then(
            (response) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: BuildingActionsTypes.CLEAR_ADD_STATE,
                    payload: {
                        map
                    }
                })
                dispatch({
                    type: BuildingActionsTypes.CREATE_BUILDING, payload: {
                        building: response.data,
                        map: map,
                        dispatch: dispatch
                    }
                })
                resolve(response.data);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    }),
    update: (id: number, data: IBuildingInput, map: any) => (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.update(id, data).then(
            (response) => {
                dispatch({
                    type: BuildingActionsTypes.UPDATE_BUILDING, payload: {
                        building: response.data,
                        map,
                        dispatch
                    }
                })
                dispatch({type: MapActionsTypes.SET_DEFAULT_MODE})
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                resolve(response.data);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    }),
    attachDepartments: (id: number, data: IBuildingDepartment[], map: any) => (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.attachDepartments(id, data).then(
            (response) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: BuildingActionsTypes.UPDATE_BUILDING, payload: {
                        building: response.data,
                        map,
                        dispatch
                    }
                })
                resolve(response);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    }),
    delete: (id: number, map: any) => (dispatch: Dispatch<object>) => new Promise((resolve, reject) => {
        dispatch({type: LoadingActionsTypes.LOADING, payload: true})
        return BuildingServices.delete(id).then(
            (response) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: BuildingActionsTypes.DELETE_BUILDING,
                    payload: {id: id, map: map},
                });
                dispatch({type: MapActionsTypes.SET_DEFAULT_MODE})
                resolve(response);
            },
            (error) => {
                dispatch({type: LoadingActionsTypes.LOADING, payload: false})
                dispatch({
                    type: AlertActionsTypes.ERROR,
                    payload: {
                        title: 'Ошибка запроса',
                        message: error.message,
                    },
                });
                reject(error);
            }
        );
    })
};
