import React, {useEffect, useState} from 'react';
import {Grid, IconButton, styled,} from '@mui/material';
import {Building} from "./Object/Building";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import {useSelector} from "react-redux";
import {IRootState} from "../../App/reducers/store";
import {Qr} from "./Object/Qr";
import {Entry} from "./Object/Entry";

const Container = styled(Grid)({
    zIndex: 1000,
    margin: 0,
    top: 0,
    position: 'absolute',
    right: 0,
    background: 'white',
    height: ' 100%',
    alignItems: 'flex-start',
    boxShadow: ' -2px -1px 2px 0 rgba(0,0,0,.1)',
    transition: '0.3s'
});

const OpenOrCloseButton = styled(IconButton)({
    position: "absolute",
    top: "50%",
    width: "25px",
    marginLeft: "-20px",
    background: "white",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "0px",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "0px",
    '&:hover': {
        background: "white",
        boxShadow: ' -2px -1px 2px 0 rgba(0,0,0,.1)',
    }
});


export const Object = () => {
    const {drawingBuilding} = useSelector((state: IRootState) => state.buildings);
    const {drawingQr} = useSelector((state: IRootState) => state.qr);
    const {drawingEntry} = useSelector((state: IRootState) => state.entries);
    const [open, setOpen] = useState<boolean>(false);

    useEffect(() => {
       if(!open && (drawingBuilding || drawingQr || drawingEntry)){
           setOpen(true)
       }else if(open && (!drawingBuilding && !drawingQr && !drawingEntry)){
           setOpen(false)
       }
    }, [open, drawingBuilding, drawingQr, drawingEntry]);


    const openOrCloseHandler = () => setOpen(!open);

    return (
        <Container
            container
            justifyContent={"flex-start"}
            alignItems={"center"}
            direction={"row"}
            style={{width: open ? '20%' : '0'}}
        >
            <OpenOrCloseButton aria-label="delete" onClick={openOrCloseHandler}>
                <KeyboardDoubleArrowLeftIcon
                    style={{transform: open ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s'}}
                />
            </OpenOrCloseButton>
            {drawingBuilding ? <Building/> : null}
            {drawingQr ? <Qr/> : null}
            {drawingEntry ? <Entry/> : null}
        </Container>
    )
}
