import Actions from "../../App/interface/actions";
import {BuildingActionsTypes, IBuilding, IBuildingState} from "../interfaces/building";
import {GetPolygon} from "../../App/helpers/polygon";
import {attachBuildingsListeners} from "../../App/helpers/building";
import {deleteItem} from "../../App/helpers/map";

const init: IBuildingState = {
    buildings: [],
    drawingBuilding: null,
    polygon: null,
    coordinates: null
};
declare var ymaps: any;


export function BuildingReducer(state: IBuildingState = init, action: Actions): IBuildingState {
    switch (action.type) {
        case BuildingActionsTypes.FETCH_BUILDINGS:
            return {
                ...state,
                ...{
                    buildings: action.payload.data
                }
            };
        case BuildingActionsTypes.ADD_BUILDING:
            const myPolygon = new ymaps.Polygon([], {
                isDraw: true
            }, {
                editorDrawingCursor: "crosshair",
                editorMaxPoints: 100,
                fillColor: "#ed5351",
                strokeColor: "#f60300",
                opacity: 0.5,
                strokeWidth: 1
            });

            action.payload.map.geoObjects.add(myPolygon);

            const stateMonitor = new ymaps.Monitor(myPolygon.editor.state);
            stateMonitor.add("drawing", function (newValue: any) {
                myPolygon.options.set("strokeColor", newValue ? '#FF0000' : '#0000FF');
            });
            myPolygon.editor.startDrawing();
            return {
                ...state,
                ...{
                    polygon: myPolygon,
                    drawingBuilding: {
                        id: -1,
                        name: '',
                        polygon: [],
                        entries: [],
                        departments: []
                    }
                }
            };
        case BuildingActionsTypes.CLEAR_ADD_STATE:
            deleteItem(action.payload.map, 'isDraw' , true);
            return {
                ...state,
                ...{
                    drawingBuilding: null,
                    polygon: null
                }
            };
        case BuildingActionsTypes.EDIT_BUILDING:
            action.payload.polygon.editor.startDrawing();
            return {
                ...state,
                ...{
                    drawingBuilding: action.payload.building,
                    polygon: action.payload.polygon
                }
            };
        case BuildingActionsTypes.COMPLETE_EDIT_BUILDING:
            if (state.polygon) {
                state.polygon.editor.stopDrawing();
            }
            return {
                ...state,
                ...{
                    drawingBuilding: null,
                    polygon: null
                }
            };
        case BuildingActionsTypes.UPDATE_BUILDING:
            deleteItem(action.payload.map, 'buildingId' , action.payload.building.id);
            const polygon = GetPolygon(action.payload.building, () => {
            });
            action.payload.map.geoObjects.add(polygon);
            attachBuildingsListeners(action.payload.building, polygon, action.payload.dispatch);
            return {
                ...state,
                ...{
                    buildings: state.buildings.map((building: IBuilding) => building.id === action.payload.building.id ? action.payload.building : building),
                    drawingBuilding: null,
                    polygon: null,
                    building: null
                }
            };
        case BuildingActionsTypes.DELETE_BUILDING:
            deleteItem(action.payload.map, 'buildingId' , action.payload.id);
            return {
                ...state,
                ...{
                    buildings: state.buildings.filter((item: IBuilding) => item.id !== action.payload.id),
                    building: null,
                    drawingBuilding: null,
                    polygon: null
                }
            };
        case BuildingActionsTypes.CREATE_BUILDING:
            if (action.payload.map) {
                const polygon = GetPolygon(action.payload.building, () => {
                });
                action.payload.map.geoObjects.add(polygon);
                attachBuildingsListeners(action.payload.building, polygon, action.payload.dispatch);
            }
            return {
                ...state,
                ...{
                    buildings: state.buildings.concat(action.payload.building)
                }
            };
        default:
            return state;
    }
}