import {HttpService} from '../../App/services/http';
import {IEntryInput} from "../interface/entry";

export const EntryServices = {
    entries: () => HttpService.get('/entries?limit=100'),
    create: (data: IEntryInput) => HttpService.put('/entries', data),
    update: (id: number, data: IEntryInput) => HttpService.post(`/entries/${id}`, data),
    delete: (id: number) => HttpService.delete(`/entries/${id}`),
};

export default EntryServices;