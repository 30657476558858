import React from "react"
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Link} from "react-router-dom";
import {styled} from "@mui/material";

const Container = styled(Stack)({
	width: "100%",
	height: '900px'
});

const BoldText = styled(Typography)({
	fontSize: "4rem",
	fontWeight: 900,
	lineHeight: 1,
	textAlign: "center",
});

const InfoText = styled(Typography)({
	marginTop: "15px",
	color: "gray",
	textAlign: "center"
});
const LinkStyles = styled(Link)({
	marginTop: "15px",
	background: "#2595FF",
	color: "white",
	fontFamily: "'Roboto', sans-serif",
	textDecoration: "none",
	padding: "12px",
	borderRadius: "3px",
	transition: "0.3s",
	"&:hover": {
		boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.12)",
	}
});

export const NotFoundPage = () => {
	return (
		<Container direction="column" alignItems="center" justifyContent="center">
			<BoldText id="shake">Что-то пошло не так</BoldText>
			<InfoText >Запрашиваемая вами страница не существует, либо была
				удалена</InfoText>
			<LinkStyles  to="/">Перейти на главную</LinkStyles>
		</Container>
	)
}
