import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, IRootState} from "../../App/reducers/store";
import {Button, ButtonGroup, Grid, Stack, styled,} from '@mui/material';
import {MapActionsTypes, MapModes} from "../../Map/interface/map";
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import CancelIcon from '@mui/icons-material/Cancel';
import {BuildingActionsTypes} from "../../Buildings/interfaces/building";
import {GetPolygon} from "../../App/helpers/polygon";
import {QrActionsTypes} from "../../Qr/interface/qr";
import {getQrCode} from "../../App/helpers/getQrCode";
import {attachQrListeners} from "../../App/helpers/qr";
import {attachBuildingsListeners} from "../../App/helpers/building";
import {EntriesActionsTypes, IEntry} from "../../Entry/interface/entry";
import {getEntry} from "../../App/data/map";
import {attachEntriesListeners} from "../../App/helpers/entry";
import Diversity1Icon from '@mui/icons-material/Diversity1';
import {DepartmentsList} from "../../Departments/components/DepartmentsList";
import {deleteItem} from "../../App/helpers/map";

const Container = styled(Grid)({
    zIndex: 1000,
    margin: '15px'
});

const CustomButton = styled(Button)({
    backgroundColor: 'white',
    borderRadius: '10px'
});

type Mode = MapModes.ADD_QR_CODES | MapModes.ADD_BUILDING | MapModes.ADD_ENTRY;

export const EditButtons = () => {
    const dispatch: Dispatch = useDispatch();
    const {mode, map, event, drawEvent} = useSelector((state: IRootState) => state.map);
    const {polygon, drawingBuilding} = useSelector((state: IRootState) => state.buildings);
    const {drawingQr} = useSelector((state: IRootState) => state.qr);
    const {drawingEntry} = useSelector((state: IRootState) => state.entries);
    const [openDepartments, setOpenDepartments] = React.useState(false);

    const handleOpenDepartments = () => setOpenDepartments(true);
    const handleCloseDepartments = () => setOpenDepartments(false)

    useEffect(() => {
        handleCloseDepartments()
    }, [drawEvent]);

    const addItem = (event: React.MouseEvent<HTMLButtonElement>, newMode: Mode) => {
        setOpenDepartments(false);
        dispatch({
            type: newMode, payload: {map}
        })
        dispatch({
            type: MapActionsTypes.CHANGE_MODE, payload: newMode
        })
    }

    const endDrawingBuilding = () => {
        if (polygon && drawingBuilding) {
            if (drawingBuilding.id > 0) {
                deleteItem(map, 'buildingId', drawingBuilding.id);
                const polygon = GetPolygon(drawingBuilding, () => {
                }, true);
                map.geoObjects.add(polygon);
                attachBuildingsListeners(drawingBuilding, polygon, dispatch);

                drawingBuilding.entries.forEach((entry: IEntry) => {
                    const entryPlacemark = getEntry(entry);
                    map.geoObjects.add(entryPlacemark);
                    attachEntriesListeners(entry, entryPlacemark, dispatch);
                });
            }
            dispatch({
                type: BuildingActionsTypes.CLEAR_ADD_STATE, payload: {
                    map
                }
            })
            polygon.editor.stopDrawing();
        }
        if (event) {
            deleteItem(map, 'qrId', -1);
            dispatch({
                type: QrActionsTypes.COMPLETE_ADD_QR
            })
        }
        if (drawingQr) {
            deleteItem(map, 'qrId', drawingQr.id);
            const newPlacemark = getQrCode(drawingQr);
            map.geoObjects.add(newPlacemark);
            attachQrListeners(drawingQr, newPlacemark, dispatch)

            dispatch({
                type: QrActionsTypes.COMPLETE_EDIT_QR
            })
        }
        if (drawingEntry) {
            deleteItem(map, 'entryId', drawingEntry.id);
            const newPlacemark = getEntry(drawingEntry);
            map.geoObjects.add(newPlacemark);
            attachEntriesListeners(drawingEntry, newPlacemark, dispatch)

            dispatch({
                type: EntriesActionsTypes.COMPLETE_EDIT_ENTRY
            })
        }
        if (openDepartments) {
            setOpenDepartments(false)
        }
        dispatch({
            type: MapActionsTypes.SET_DEFAULT_MODE
        })
    }

    return (
        <Container container justifyContent={"felx-start"} alignItems={"center"} direction={"row"}>
            <Grid item>
                <Stack direction="column" spacing={2}>
                    {mode === MapModes.DEFAULT_MODE ?
                        <React.Fragment>
                            <ButtonGroup aria-label="outlined button group">
                                <CustomButton variant="contained" startIcon={<DomainAddIcon/>} color={"inherit"}
                                              onClick={(e) => addItem(e, MapModes.ADD_BUILDING)}>
                                    Здание
                                </CustomButton>
                                <CustomButton variant="contained" startIcon={<QrCodeIcon/>} color={"inherit"}
                                              onClick={(e) => addItem(e, MapModes.ADD_QR_CODES)}>
                                    QR-Код
                                </CustomButton>
                                <CustomButton variant="contained" startIcon={<MeetingRoomIcon/>} color={"inherit"}
                                              onClick={(e) => addItem(e, MapModes.ADD_ENTRY)}>
                                    Вход
                                </CustomButton>
                                <CustomButton variant="contained" startIcon={<Diversity1Icon/>} color={"inherit"}
                                              onClick={handleOpenDepartments}>
                                    Отделения
                                </CustomButton>
                            </ButtonGroup>
                        </React.Fragment> : null}
                    {[MapModes.EDIT_MODE, MapModes.ADD_BUILDING, MapModes.ADD_QR_CODES, MapModes.ADD_ENTRY,].includes(mode) ?
                        <CustomButton variant="contained" startIcon={<CancelIcon/>} color={"inherit"}
                                      onClick={endDrawingBuilding}>
                            Отмена
                        </CustomButton> : null}
                    <DepartmentsList openDepartments={openDepartments} handleCloseDepartments={handleCloseDepartments}/>
                </Stack>
            </Grid>
        </Container>
    )
}
