import {Button, Grid, IconButton, InputAdornment, styled, TextField, Typography} from '@mui/material';
import {FormikValues, useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, IRootState} from '../../../App/reducers/store';
import {IBuilding, IBuildingInput} from "../../../Buildings/interfaces/building";
import {BuildingActions} from "../../../Buildings/actions/building";
import * as yup from "yup";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import {IBuildingDepartment, IBuildingDepartmentInput} from "../../../Departments/interface/department";
import SearchIcon from "@mui/icons-material/Search";
import {StyledAutocomplete} from "../../../App/components/Select/Autocomplete";

const FormContainer = styled(Grid)(() => ({
    width: "100%",
    padding: "20px",
    height: "inherit",
    overflowY: "scroll"
}))

const Item = styled(Grid)(() => ({
    width: "100%",
    marginTop: "25px",
}))

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Заполните наименование корпуса!'),
    departments: yup.array(yup.object({
        name: yup.string().required('Заполните наименование отделения!'),
        floor: yup.number().required('Заполните этаж отделения!').integer(),
    }))
});

export const Building = () => {
    const dispatch: Dispatch = useDispatch();
    const {map} = useSelector((state: IRootState) => state.map);
    const {drawingBuilding, polygon} = useSelector((state: IRootState) => state.buildings);
    const {departments} = useSelector((state: IRootState) => state.departments);

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            departments: (drawingBuilding?.departments ?? []).map((value) => {
                return {
                    id: value.id,
                    name: value.name,
                    floor: value.floor,
                    working_hours: value.working_hours,
                    phone: value.working_hours,
                    department: {
                        id: value.department_id,
                        name: value.name
                    }
                }
            }),
            name: drawingBuilding?.name,
        },
        onSubmit: (values: FormikValues) => {
            if (drawingBuilding && polygon) {
                polygon.editor.events.add("drawingstop", function (e: any) {
                    const coordinates = e.get("target").geometry.getCoordinates();

                    const data: IBuildingInput = {
                        name: values.name ?? '',
                        polygon: coordinates[0]
                    }
                    dispatch(drawingBuilding?.id > 0 ? BuildingActions.update(drawingBuilding.id, data, map) : BuildingActions.create(data, map)).then(
                        (resp: any) => {
                            const response = resp as IBuilding;
                            dispatch(BuildingActions.attachDepartments(response.id, values.departments as IBuildingDepartment[], map)).then(
                                () => {

                                },
                                () => {
                                },
                            )
                        },
                        () => {
                        },
                    )
                });
                polygon.editor.stopDrawing();
            }
        },
    });

    const addDepartment = () => formik.setFieldValue('departments', [{
        name: '',
        phone: '',
        working_hours: '',
        floor: 0
    }].concat(formik.values.departments ?? []))

    const deleteDepartment = (index: number) => {
        const data = formik.values?.departments;
        data?.splice(index, 1)
        formik.setFieldValue('departments', data)
    }

    const deleteOnClick = () => {
        if (drawingBuilding) {
            dispatch(BuildingActions.delete(drawingBuilding.id, map)).then(
                () => {
                },
                () => {
                },
            )
        }
    }

    return (
        <FormContainer>
            <Typography>Здание</Typography>
            <form onSubmit={formik.handleSubmit}>
                <Item>
                    <TextField
                        fullWidth
                        id="name"
                        name={'name'}
                        type="text"
                        label="Название корпуса"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        helperText={formik.touched.name && formik.errors.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon/>}
                        style={{marginTop: '15px'}}
                        onClick={addDepartment}
                    >
                        Добавить отделение
                    </Button>
                </Item>
                {formik.values?.departments?.map((department: IBuildingDepartmentInput, index: number) => (
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        key={index}
                        justifyContent="center"
                        style={{border: '1px solid #ededed', padding: '5px', borderRadius: '10px', marginTop: '10px'}}
                    >
                        <Grid
                            container
                            direction="row"
                            alignItems="center"
                            key={index}
                            justifyContent="flex-end"
                        >
                            <IconButton aria-label="delete" size="small" onClick={() => deleteDepartment(index)}>
                                <DeleteIcon color="error"/>
                            </IconButton>
                            <Item>
                                <StyledAutocomplete
                                    fullWidth
                                    disablePortal
                                    size="small"
                                    id="building_id"
                                    disableClearable
                                    options={departments}
                                    value={department.department}
                                    getOptionLabel={(option: IBuildingDepartmentInput | any) => option.name}
                                    onBlur={formik.handleBlur}
                                    onChange={(e: any, value: IBuildingDepartmentInput | any) => {
                                        formik.setFieldValue(`departments.${index}.department`, value)
                                        formik.setFieldValue(`departments.${index}.id`, value.id)
                                        formik.setFieldValue(`departments.${index}.name`, value.name)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            fullWidth
                                            required
                                            size="small"
                                            placeholder="Введите название отделения"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                />
                            </Item>
                            <Item>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    required
                                    id={`departments.${index}.floor`}
                                    name={`departments.${index}.floor`}
                                    label="Этаж"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={department.floor}
                                />
                            </Item>
                            <Item>
                                <TextField
                                    size="small"
                                    fullWidth
                                    type="text"
                                    required
                                    id={`departments.${index}.working_hours`}
                                    name={`departments.${index}.working_hours`}
                                    label="Время работы"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={department.working_hours}
                                />
                            </Item>
                            <Item>
                                <TextField
                                    size="small"
                                    fullWidth
                                    required
                                    type="text"
                                    id={`departments.${index}.phone`}
                                    name={`departments.${index}.phone`}
                                    label="Телефон"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={department.phone}
                                />
                            </Item>
                        </Grid>
                    </Grid>
                ))}
                <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    style={{marginTop: '10px'}}
                >
                    <Button
                        type="submit"
                        variant="contained"
                    >
                        Сохранить
                    </Button>
                    <Button
                        onClick={deleteOnClick}
                        variant="contained"
                        color="error"
                    >
                        Удалить
                    </Button>
                </Grid>
            </form>
        </FormContainer>
    );
};