import {Button, Grid, Paper, TextField} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {AuthActions} from './actions/auth';
import {Dispatch} from "../App/reducers/store";

const RootContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#edeef0',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  padding: theme.spacing(4),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const Title = styled('h2')({
  textAlign: 'center',
});

const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const dispatch: Dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setLogin(event.target.value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  const handleLogin = async () => {
    await dispatch(
      AuthActions.login({
        login,
        password,
      })
    ).then(
      () => {
        navigate('/admin');
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  };

  return (
    <RootContainer>
      <StyledPaper>
        <Title>Авторизация</Title>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <StyledTextField
              type="text"
              label="Логин"
              variant="outlined"
              value={login}
              onChange={handleEmailChange}
              fullWidth
            />
            <StyledTextField
              type="password"
              label="Пароль"
              variant="outlined"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
              fullWidth
            >
              Войти
            </Button>
          </Grid>
        </Grid>
      </StyledPaper>
    </RootContainer>
  );
};

export default Login;
