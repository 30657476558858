import Actions from "../../App/interface/actions";
import {DepartmentsActionsTypes, IBuildingDepartment, IDepartment, IDepartmentState} from "../interface/department";

const initialState: IDepartmentState = {
    departments: []
}


export const DepartmentReducer = (state: IDepartmentState = initialState, action: Actions): IDepartmentState => {
    switch (action.type) {
        case DepartmentsActionsTypes.FETCH_DEPARTMENTS:
            return {
                ...state,
                ...{
                    departments: action.payload.data
                }
            };
        case DepartmentsActionsTypes.UPDATE_DEPARTMENT:
            return {
                ...state,
                ...{
                    departments: state.departments.map((item: IDepartment) => item.id === action.payload.id ? action.payload : item)
                }
            };
        case DepartmentsActionsTypes.CREATE_DEPARTMENT:
            return {
                ...state,
                ...{
                    departments: state.departments.concat(action.payload)
                }
            };
        case DepartmentsActionsTypes.UPSERT_DEPARTMENTS:
            return {
                ...state,
                ...{
                    departments: action.payload
                }
            };
        case DepartmentsActionsTypes.DELETE_DEPARTMENT:
            return {
                ...state,
                ...{
                    departments: state.departments.filter((department: IBuildingDepartment) => department.id !== action.payload)
                }
            };
        default:
            return state
    }
}
