import React, {useEffect} from 'react';
import {Button, Grid, styled, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {Dispatch, IRootState} from "../../../App/reducers/store";
import * as yup from "yup";
import {useFormik} from "formik";
import {QrActions} from "../../../Qr/actions/qr";
import {IQRInput, QrActionsTypes} from "../../../Qr/interface/qr";
import {getQrCode} from "../../../App/helpers/getQrCode";
import {MapActionsTypes} from "../../../Map/interface/map";

const CustomForm = styled('form')({
    backgroundColor: 'white',
    padding: '15px',
    borderRadius: '10px',
    width: '100%'
});

const validationSchema = yup.object({
    name: yup
        .string()
        .required('Заполните наименование!'),
});

const Item = styled(Grid)(() => ({
    marginTop: '15px',
    width: "100%",
}))

export const Qr = () => {
    const dispatch: Dispatch = useDispatch();
    const {drawingQr, placemark} = useSelector((state: IRootState) => state.qr);
    const {event, map} = useSelector((state: IRootState) => state.map);

    useEffect(() => {
        if (event && drawingQr) {
            const coordinates = event.get('coords');
            map.geoObjects.add(getQrCode({
                ...drawingQr,
                latitude: coordinates[0],
                longitude: coordinates[1]
            }));
        }
    }, [dispatch, map, event, drawingQr]);

    const initialValues = {
        name: drawingQr?.name,
        latitude: drawingQr?.latitude,
        longitude: drawingQr?.longitude,
    }

    useEffect(() => {
        if (drawingQr) {
            formik.setValues(initialValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawingQr]);

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (drawingQr) {
                if (event) {
                    const cooordinates = event.get('coords');
                    const data: IQRInput = {
                        name: values.name ?? '',
                        latitude: cooordinates[0],
                        longitude: cooordinates[1]
                    }
                    dispatch(QrActions.create(data, map)).then(
                        () => dispatch({
                            type: QrActionsTypes.COMPLETE_ADD_QR
                        }),
                        () => {
                        },
                    )
                } else if (placemark) {
                    placemark.editor.events.add("drawingstop", function (e: any) {
                        const coordinates = e.get("target").geometry.getCoordinates();

                        const data: IQRInput = {
                            name: values.name ?? '',
                            latitude: coordinates[0],
                            longitude: coordinates[1]
                        }
                        dispatch(QrActions.update(drawingQr.id, data, map)).then(
                            () => dispatch({
                                type: QrActionsTypes.COMPLETE_EDIT_QR
                            }),
                            () => {
                            },
                        )
                    });
                    placemark.editor.stopDrawing();
                }
                dispatch({
                    type: MapActionsTypes.SET_DEFAULT_MODE
                })
            }

        },
    });

    const printHandler = () => {
        if (drawingQr) {
            dispatch(QrActions.print(drawingQr.id)).then(
                () => {
                },
                () => {
                },
            )
        }
    }

    const deleteOnClick = () => {
        if (drawingQr) {
            dispatch(QrActions.delete(drawingQr.id, map)).then(
                () => {
                },
                () => {
                },
            )
        }
    }


    return <CustomForm onSubmit={formik.handleSubmit}>
        <Typography>QR - код</Typography>
        <Item>
            <TextField
                fullWidth
                id="name"
                size="small"
                name="name"
                label="Наименование"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                type="text"
            />
        </Item>
        <Item display={'flex'} gap={2}>
            <Button
                disabled={((drawingQr?.id ?? 0) <= 0)}
                onClick={printHandler}
                type="submit"
                variant="contained"
            >
                Распечатать
            </Button>
            <Button
                type="submit"
                variant="contained"
                disabled={!formik.isValid}
            >
                Сохранить
            </Button>
            <Button
                onClick={deleteOnClick}
                variant="contained"
                color="error"
            >
                Удалить
            </Button>
        </Item>
    </CustomForm>
}
