export interface IDepartmentState {
    departments: IBuildingDepartment[];
}

export enum DepartmentsActionsTypes {
    FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS',
    CREATE_DEPARTMENT = 'CREATE_DEPARTMENT',
    UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT',
    DELETE_DEPARTMENT = 'DELETE_DEPARTMENT',
    UPSERT_DEPARTMENTS = 'UPSERT_DEPARTMENTS',
}


export interface IBuildingDepartment {
    id: number;
    name: string;
    department_id: number;
    phone: string;
    working_hours: string;
    floor: number;
}

export interface IBuildingDepartmentInput extends Omit<IBuildingDepartment, 'department_id'> {
    department: IDepartment
}

export interface IDepartment {
    id: number;
    name: string;
}

export interface IDepartmentInput extends Omit<IDepartment, 'id'> {
}