import {Autocomplete, styled} from "@mui/material";


export const StyledAutocomplete = styled(Autocomplete)(() => ({
    borderRadius: '15px',
    width: '100%',
    background: 'white',
    '&:hover': {
        cursor: 'pointer'
    }
}));