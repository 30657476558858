import CloseIcon from '@mui/icons-material/Close';
import {Alert as MuiAlert, IconButton} from "@mui/material";
import React from "react";

type Props = {
	children: React.ReactNode;
	className: string;
	onClose: () => void;
}

export default function Alert(props: Props): JSX.Element {
	const {onClose} = props
	return (
		<MuiAlert
			icon={false}
			elevation={6}
			variant="filled"
			{...props}
			action={
				<IconButton
					onClick={onClose}
					size="small"
				>
					<CloseIcon style={{color: "#495055"}}/>
				</IconButton>
			}
		/>
	)
}

