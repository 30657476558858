import {Button, Grid, IconButton, Modal, styled, TextField, Typography} from '@mui/material';
import {FormikValues, useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch, IRootState} from '../../App/reducers/store';
import {IDepartment} from '../interface/department';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import * as yup from "yup";
import {DepartmentActions} from '../actions/department';
import {useEffect} from "react";

const CustomModal = styled(Modal)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    background: 'white',
    borderRadius: '15px',
    '& .MuiBackdrop-root': {
        backgroundColor: 'unset',
    },
});

const ModalContent = styled(Grid)({
    width: '100%',
    height: '100%',
    borderRadius: '15px',
    boxShadow: '0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -2px rgba(0,0,0,.1)'
});

const FormContainer = styled(Grid)(() => ({
    width: "100%",
    height: "calc(100% - 110px)",
    overflowY: "scroll",
    borderTopStyle: 'solid',
    borderTopWidth: '0.5px',
    borderTopColor: '#c6c6c6',
    borderBottomStyle: 'solid',
    borderBottomWidth: '0.5px',
    borderBottomColor: '#c6c6c6',
    padding: '10px',
}))

const Item = styled(Grid)(() => ({
    gap: '1rem',
    width: "100%",
    display: 'flex',
    marginTop: "25px",
    alignItems: 'center',
}))

const CustomForm = styled('form')(() => ({
    width: '100%',
    height: '-webkit-fill-available',
}))

interface DepartmentListProps {
    openDepartments: boolean,
    handleCloseDepartments: () => void
}

const validationSchema = yup.object({
    departments: yup.array(yup.object({
        name: yup.string().required('Заполните наименование отделения!'),
    }))
});

export const DepartmentsList = (props: DepartmentListProps) => {
    const dispatch: Dispatch = useDispatch();
    const {departments} = useSelector((state: IRootState) => state.departments)

    const addDepartment = () => formik.setFieldValue('departments', [{
        name: '',
    }].concat(formik.values.departments ?? []))

    const deleteDepartment = (department: IDepartment, index: number) => {
        const data = formik.values?.departments;
        data?.splice(index, 1)
        formik.setFieldValue('departments', data)
        if (department.id) {
            dispatch(DepartmentActions.delete(department.id)).then(
                () => {
                },
                () => {
                },
            )
        }
    }

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            departments: departments,
        },
        onSubmit: (values: FormikValues) => {
            dispatch(DepartmentActions.upsert(values.departments)).then(
                () => {
                    props.handleCloseDepartments()
                },
                () => {
                },
            )
            props.handleCloseDepartments();
        },
    });

    useEffect(() => {
        if (departments.length !== formik.values.departments.length && !props.openDepartments) {
            formik.setValues({
                departments: departments,
            })
        }
    }, [formik, departments, props.openDepartments]);

    return (
        <CustomModal
            open={props.openDepartments}
            onClose={props.handleCloseDepartments}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <ModalContent style={{background: 'white'}}>
                <Typography sx={{textAlign: 'center', fontWeight: 600, pb: 2, fontSize: '1.5rem', paddingBottom: 0}}>Список
                    отделений</Typography>
                <CustomForm onSubmit={formik.handleSubmit}>
                    <FormContainer>
                        {formik.values?.departments?.map((department: IDepartment, index: number) => (
                            <Grid
                                container
                                direction="column"
                                alignItems="center"
                                key={department.id}
                                justifyContent="center"
                            >
                                <Item>
                                    <TextField
                                        fullWidth
                                        type="text"
                                        value={department.name}
                                        label="Название отделения"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        id={`departments.${index}.name`}
                                        name={`departments.${index}.name`}
                                    />
                                    <IconButton
                                        aria-label="delete"
                                        onClick={() => deleteDepartment(department, index)}
                                    >
                                        <DeleteForeverIcon color="error" sx={{cursor: 'pointer'}}/>
                                    </IconButton>
                                </Item>
                            </Grid>
                        ))}
                    </FormContainer>
                    <Button
                        color="primary"
                        variant="contained"
                        startIcon={<AddIcon/>}
                        style={{marginTop: '15px', marginLeft: '20px'}}
                        onClick={addDepartment}
                    >
                        Добавить отделение
                    </Button>
                    <Button
                        disabled={!formik.dirty}
                        type='submit'
                        color="primary"
                        variant="contained"
                        style={{marginTop: '15px', marginLeft: '20px'}}
                    >
                        Сохранить изменения
                    </Button>
                </CustomForm>
            </ModalContent>
        </CustomModal>
    )
}
