export interface IEntryState {
    entries: IEntry[];
    drawingEntry: IEntry | null,
    placemark: any
}

export enum EntriesActionsTypes {
    FETCH_ENTRIES = 'FETCH_ENTRIES',
    CREATE_ENTRY = 'CREATE_ENTRY',
    COMPLETE_ADD_ENTRY = 'COMPLETE_ADD_ENTRY',
    DELETE_ENTRY = 'DELETE_ENTRY',
    EDIT_ENTRY = 'EDIT_ENTRY',
    COMPLETE_EDIT_ENTRY = 'COMPLETE_EDIT_ENTRY'
}


export interface IEntry {
    id: number;
    building_id: number;
    latitude: number;
    longitude: number;
}

export interface IEntryInput extends Omit<IEntry, 'id'|'created_at'|'updated_at'> {
}