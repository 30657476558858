export interface IQrState {
    qrs: IQr[];
    drawingQr: IQr | null,
    placemark: any
}

export enum QrActionsTypes {
    FETCH_QRS = 'FETCH_QRS',
    CREATE_QR = 'CREATE_QR',
    COMPLETE_ADD_QR = 'COMPLETE_ADD_QR',
    DELETE_QR = 'DELETE_QR',
    EDIT_QR = 'EDIT_QR',
    COMPLETE_EDIT_QR = 'COMPLETE_EDIT_QR',
    UPDATE_QR = 'UPDATE_QR'
}

export interface IQr {
    id: number;
    name: string;
    latitude: number;
    longitude: number;
}

export interface IQRInput extends Omit<IQr, 'id'> {
}