import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {PageFrame} from "../App/components/PageFrame";
import {TargetActionsTypes} from "../Target/interface/target";
import YandexMap from "../YandexMap/YandexMap";
import {MapActionsTypes} from "./interface/map";
import {styled} from '@mui/material/styles';
import SelectAutocomplete from '../App/components/Select/SelectAutocomplete';

const CustomPageFrame = styled(PageFrame)(() => ({
    width: "100%",
    height: "10%",
    background: 'transparent',
    boxShadow: 'none',
    display: "flow-root",
    position: "absolute",
    alignItems: "flex-start",
    gap: "1rem",
    zIndex: 1,
}));

const Contacts = styled('div')(() => ({
    width: "100%",
    fontSize: "14px",
    fontWeight: "400",
    letterSpacing: "1px",
    textAlign: "center",
    fontFamily: "'Roboto', sans-serif",
    position: 'absolute',
    bottom: 0,
    zIndex: 1,
    background: "white",
    paddingTop: '10px',
    paddingBottom: '10px'
}));

const MapPage: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({type: MapActionsTypes.SET_MAP, payload: null})
    }, [dispatch]);

    const clearTargets = () => dispatch({type: TargetActionsTypes.SET_TARGET_TO, payload: []})

    return (
        <React.Fragment>
            <CustomPageFrame>
                <SelectAutocomplete clearCallback={() => clearTargets()}/>
            </CustomPageFrame>
            <Contacts>
                Контакты: 8(800)700-07-01
            </Contacts>
            <YandexMap isAdmin={false}/>
        </React.Fragment>
    );
};

export default MapPage;
