import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TargetActionsTypes} from '../../../Target/interface/target';
import {Dispatch, IRootState} from "../../reducers/store";
import {styled} from '@mui/material/styles';
import {FormControl, Grid, IconButton, InputAdornment, TextField} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {PageFrame} from "../PageFrame";
import ClearIcon from '@mui/icons-material/Clear';
import {IEntry} from "../../../Entry/interface/entry";
import {IBuildingDepartment} from "../../../Departments/interface/department";
import {StyledAutocomplete} from "./Autocomplete";

const Wrapper = styled(PageFrame)(() => ({
    width: '100%',
    height: '95%',
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'center',
}));

const CustomStyledAutocomplete = styled(StyledAutocomplete)(() => ({
    "& .MuiOutlinedInput-root": {
        "& > fieldset": {
            border: "unset",
        },
    },
}));

const StyledFormControl = styled(FormControl)(() => ({
    width: '95%',
    marginLeft: 'auto',
    marginRight: 'auto'
}));

const ButtonGrid = styled(Grid)(() => ({
    borderRadius: '15px',
    background: 'white',

}));

const StyledIconButton = styled(IconButton)(() => ({
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    height: '56px'
}));

interface SelectAutocompleteProps {
    clearCallback: () => void;
}

const SelectAutocomplete: React.FC<SelectAutocompleteProps> = ({clearCallback}) => {
    const dispatch: Dispatch = useDispatch();
    const {buildings} = useSelector((state: IRootState) => state.buildings);
    const {departments} = useSelector((state: IRootState) => state.departments);
    const [selectionOption, setSelectionOption] = useState<IBuildingDepartment | null>(null);

    const handleSelectOption = (id: number) => {
        const building = buildings.find((building) => building.departments.find((department: IBuildingDepartment) => department.id === id));

        if (building) {
            dispatch({
                type: TargetActionsTypes.SET_TARGET_TO,
                payload: building.entries.map((entry: IEntry) => [entry.latitude, entry.longitude]),
            });
        }
    };

    const clear = () => {
        setSelectionOption(null);
        dispatch({
            type: TargetActionsTypes.SET_TARGET_TO,
            payload: null,
        });
        clearCallback();
    }

    const onChange = (e: React.SyntheticEvent, option: IBuildingDepartment | any) => {
        if (option) {
            setSelectionOption(option);
            handleSelectOption(option.id);
        } else {
            clear()
        }
    }

    return (
        <Wrapper>
            <StyledFormControl>
                <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item sm={10.9} xs={9.8} md={11} lg={11.5}>
                        <CustomStyledAutocomplete
                            fullWidth
                            disablePortal
                            id="combo-box-demo"
                            options={departments}
                            value={selectionOption}
                            getOptionLabel={(option: IBuildingDepartment | any) => option.name}
                            onChange={onChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    placeholder="Введите название отделения"
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <ButtonGrid item sm={1} xs={2} md={0.8} lg={0.4}>
                        <StyledIconButton aria-label="delete" size="large" onClick={clear}>
                            <ClearIcon fontSize="inherit" color={"error"}/>
                        </StyledIconButton>
                    </ButtonGrid>
                </Grid>
            </StyledFormControl>
        </Wrapper>
    );
};

export default SelectAutocomplete;
