export const calculateDistance = (point1: any, point2: any): number => {
  const R = 6371;
  const lat1Rad = (Math.PI * point1[0]) / 180;
  const lat2Rad = (Math.PI * point2[0]) / 180;
  const deltaLat = (Math.PI * (point2[0] - point1[0])) / 180;
  const deltaLon = (Math.PI * (point2[1] - point1[1])) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c;
  return distance;
};

export const findNearestPoint = (startPoint: any, endPoints: any[]): any => {
  let nearestPoint = endPoints[0];
  let nearestDistance = calculateDistance(startPoint, nearestPoint);

  for (let i = 1; i < endPoints.length; i++) {
    const currentDistance = calculateDistance(startPoint, endPoints[i]);
    if (currentDistance < nearestDistance) {
      nearestDistance = currentDistance;
      nearestPoint = endPoints[i];
    }
  }

  return nearestPoint;
};
