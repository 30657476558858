import {IBuilding} from "../../Buildings/interfaces/building";
import {IBuildingDepartment} from "../../Departments/interface/department";

declare var ymaps: any;

export const GetPolygon = (item: IBuilding, callback: () => void, isAdmin: boolean = false) => {
    const departmentsHtml = item.departments.map((department: IBuildingDepartment, index: number) => `<div><b>${index+1}. ${department.name}</b><br> Телефон: ${department.phone}<br>Часы работы: ${department.working_hours}</div>`).join('');
    const BalloonContentLayout = ymaps.templateLayoutFactory.createClass(
        '<div style="margin: 10px; display: flex; flex-direction: column;">' +
        '<div style="font-weight: 600; padding: 0.5rem 0; font-size: 1.25rem; text-align: left">' + (item.name) + '</div>' +
        
        '<div style="font-weight: 600; padding: 0.5rem 0; font-size: 1rem">Список отделений:</div>' +
        departmentsHtml +
        '<button ' + (!item.departments.length ? 'disabled' : '') + ' style="background: white; padding: 5px; border: 1px solid #a8a8a8" id="route-button"> Маршрут сюда </button>' +
        '</div>', {

            build: function () {
                BalloonContentLayout.superclass.build.call(this);
                const button = this.getParentElement().querySelector('#route-button');
                button.addEventListener('click', this.onRouteClick.bind(this));
            },

            clear: function () {
                const button = this.getParentElement().querySelector('#route-button');
                button.removeEventListener('click', this.onRouteClick.bind(this));
                BalloonContentLayout.superclass.clear.call(this);
            },

            onRouteClick: callback,
        });

    if (isAdmin) {
        return new ymaps.Polygon([item.polygon], {
            buildingId: item.id
        }, {
            editorDrawingCursor: "crosshair",
            editorMaxPoints: 100,
            fillColor: "#ed5351",
            strokeColor: "#f60300",
            opacity: 0.5,
            strokeWidth: 1
        });
    }

    return new ymaps.GeoObject(
        {
            geometry: {
                type: "Polygon",
                coordinates: [item.polygon],
                fillRule: "nonZero",
            },
            properties: {
                hintContent: item.name,
            },
        },
        {
            fillColor: "#ed5351",
            strokeColor: "#f60300",
            opacity: 0.5,
            strokeWidth: 5,
            strokeStyle: "solid",
            balloonContentLayout: BalloonContentLayout
        }
    );

}