import {HttpService} from '../../App/services/http';
import {IBuildingInput} from "../interfaces/building";
import {IBuildingDepartment} from "../../Departments/interface/department";

export const BuildingServices = {
    buildings: () => HttpService.get('/buildings?limit=100'),
    building: (id: number) => HttpService.get(`/buildings/${id}`),
    create: (data: IBuildingInput) => HttpService.put('/buildings', data),
    update: (id: number, data: IBuildingInput) => HttpService.post(`/buildings/${id}`, data),
    delete: (id: number) => HttpService.delete(`/buildings/${id}`),
    attachDepartments: (id: number, data: IBuildingDepartment[]) => HttpService.post(`/buildings/${id}/departments`, {departments: data}),
};

export default BuildingServices;