import {AlertActionsTypes} from "../../App/interface/alert"
import QrServices from "../services/qr";
import {IQRInput, QrActionsTypes} from "../interface/qr";
import {MapActionsTypes} from "../../Map/interface/map";

export const QrActions = {
    qrs: () => (dispatch: any) => new Promise((resolve, reject) => QrServices.qrs().then(
        response => {
            dispatch({type: QrActionsTypes.FETCH_QRS, payload: response})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    create: (data: IQRInput, map: any) => (dispatch: any) => new Promise((resolve, reject) => QrServices.create(data).then(
        response => {
            dispatch({
                type: QrActionsTypes.CREATE_QR, payload: {
                    qr: response.data,
                    map: map,
                    dispatch: dispatch
                }
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    update: (id: number, data: IQRInput, map: any) => (dispatch: any) => new Promise((resolve, reject) => QrServices.update(id, data).then(
        response => {
            dispatch({
                type: QrActionsTypes.UPDATE_QR, payload: {
                    qr: response.data,
                    map,
                    dispatch
                }
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    delete: (id: number, map: any) => (dispatch: any) => new Promise((resolve, reject) => QrServices.delete(id).then(
        response => {
            dispatch({
                type: QrActionsTypes.DELETE_QR, payload: {
                    id: id,
                    map: map,
                    dispatch: dispatch
                }
            })
            dispatch({type: MapActionsTypes.SET_DEFAULT_MODE})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    print: (id: number) => (dispatch: any) => new Promise((resolve, reject) => QrServices.print(id).then(
        response => {
            const file = new File([response], `qr_${id}.png`, {type: "image/png"});
            const fileURL = URL.createObjectURL(file);
            const printWindow = window.open(fileURL, "_blank");
            if (printWindow) {
                printWindow.onload = function () {
                    printWindow.print();
                };
            }
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    ))
}
