import {AlertActionsTypes} from "../../App/interface/alert"
import DepartmentServices from "../services/departments";
import {DepartmentsActionsTypes, IDepartmentInput} from "../interface/department";

export const DepartmentActions = {
    departments: () => (dispatch: any) => new Promise((resolve, reject) => DepartmentServices.departments().then(
        response => {
            dispatch({type: DepartmentsActionsTypes.FETCH_DEPARTMENTS, payload: response})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    create: (data: IDepartmentInput) => (dispatch: any) => new Promise((resolve, reject) => DepartmentServices.create(data).then(
        response => {
            dispatch({
                type: DepartmentsActionsTypes.CREATE_DEPARTMENT, payload: response.data
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    update: (id: number, data: IDepartmentInput) => (dispatch: any) => new Promise((resolve, reject) => DepartmentServices.update(id, data).then(
        response => {
            dispatch({
                type: DepartmentsActionsTypes.UPDATE_DEPARTMENT, payload: response.data
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    upsert: (data: IDepartmentInput[]) => (dispatch: any) => new Promise((resolve, reject) => DepartmentServices.upsert(data).then(
        response => {
            dispatch({
                type: DepartmentsActionsTypes.UPSERT_DEPARTMENTS, payload: response.data
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    delete: (id: number) => (dispatch: any) => new Promise((resolve, reject) => DepartmentServices.delete(id).then(
        response => {
            dispatch({
                type: DepartmentsActionsTypes.DELETE_DEPARTMENT, payload: id
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
}
