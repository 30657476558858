import {cx} from '@emotion/css';
import {AlertTitle, Snackbar} from '@mui/material';
import {styled} from '@mui/material/styles';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AlertActionsTypes, IAlertState} from '../interface/alert';
import {RootState} from "../reducers/store";

import Alert from './Alert';

const PREFIX = 'Alert';

const classes = {
	alert: `${PREFIX}-alert`,
	alert_error: `${PREFIX}-alert-error`,
	alert_success: `${PREFIX}-alert-success`,
	alert_warning: `${PREFIX}-alert-warning`,
	alert_message: `${PREFIX}-alert_message`,
	alert_description: `${PREFIX}-alert-description`,
};

const Root = styled('div')(({theme}) => ({
	[`& .${classes.alert}`]: {
		width: '350px',
		color: '#495055',
		borderRadius: '2px',
		background: 'white',
	},
	[`& .${classes.alert_error}`]: {
		border: `1px solid ${theme.palette.error.main}`,
	},
	[`& .${classes.alert_success}`]: {
		border: `1px solid ${theme.palette.success.main}`,
	},
	[`& .${classes.alert_warning}`]: {
		border: `1px solid ${theme.palette.warning.main}`,
	},
	[`& .${classes.alert_message}`]: {
		paddingTop: '2px',
		color: '#495055',
		fontFamily: 'Open Sans',
		fontWeight: 600,
		lineHeight: '20px',
	},
	[`& .${classes.alert_description}`]: {
		color: '#495055',
		fontFamily: 'Open Sans',
		fontWeight: 400,
		fontSize: '14px',
		lineHeight: '20px',
	},
}));

export default function Alerts(): JSX.Element {
	const dispatch = useDispatch();
	const {alerts} = useSelector((state: RootState) => state.alerts);

	const onClose = (reason?: string | null, item?: IAlertState) => {
		if (reason === 'clickaway') {
			return;
		}
		dispatch({type: AlertActionsTypes.CLOSE, payload: item});
	};

	const getAlerts = (): JSX.Element[] => {
		return alerts.length
			? alerts.map((alert: IAlertState, index: number) => {
				return (
					<Snackbar
						key={(Math.random() + 1).toString(36).substring(7)}
						open={true}
						onClose={(e, reason) => onClose(reason, alert)}
						autoHideDuration={3000}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						style={{
							marginTop: `${index ? index * 90 : 16}px`,
							width: '250px',
						}}
					>
						<Root>
							<Alert
								onClose={() => onClose(null, alert)}
								className={cx(classes.alert, classes[`alert_${alert.type}`])}
							>
								<AlertTitle className={classes.alert_message}>
									{alert.title}
								</AlertTitle>
								{alert.message}
							</Alert>
						</Root>
					</Snackbar>
				);
			})
			: [];
	};

	return <React.Fragment>{getAlerts()}</React.Fragment>;
}
