import React from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import {Button, Grid, styled, Tooltip, tooltipClasses, TooltipProps,} from '@mui/material';
import {EditButtons} from "./EditButtons";
import {Object} from "./Object";
import LogoutIcon from '@mui/icons-material/Logout';
import {Dispatch} from '../../App/reducers/store';
import {AuthActions} from '../../Auth/actions/auth';

const CustomButton = styled(Button)({
    backgroundColor: 'white',
    borderRadius: '10px',
    padding: '8px 0px 8px 10px'
});

const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export const EditPanel = () => {
    const dispatch: Dispatch = useDispatch();
    const navigate = useNavigate()

    const logout = () => {
        return dispatch(AuthActions.logout()).then(
            () => navigate('/login'),
            () => {
            }
        )
    }
    return (
        <React.Fragment>
            <Grid
                sx={{
                    top: '0',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    position: 'absolute',
                }}
            >
                <EditButtons/>
                <Grid
                    container
                    alignItems="center"
                    sx={{
                        width: '100px',
                        cursor: 'pointer'
                    }}
                    onClick={logout}
                >
                    <LightTooltip title="Выйти" arrow>
                        <CustomButton variant="contained" startIcon={<LogoutIcon/>} color={"inherit"}>
                        </CustomButton>
                    </LightTooltip>
                </Grid>
            </Grid>
            <Object/>
        </React.Fragment>

    )
}
