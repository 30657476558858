import {IQr, IQrState, QrActionsTypes} from '../interface/qr';
import Actions from "../../App/interface/actions";
import {getQrCode} from "../../App/helpers/getQrCode";
import {MapModes} from "../../Map/interface/map";
import {attachQrListeners} from "../../App/helpers/qr";
import {deleteItem} from "../../App/helpers/map";

const initialState: IQrState = {
    qrs: [],
    drawingQr: null,
    placemark: null
}


export const QrReducer = (state: IQrState = initialState, action: Actions): IQrState => {
    switch (action.type) {
        case QrActionsTypes.FETCH_QRS:
            return {
                ...state,
                ...{
                    qrs: action.payload.data
                }
            };
        case QrActionsTypes.EDIT_QR:
            if (!state.placemark) {
                action.payload.placemark.editor.startDrawing();
                return {
                    ...state,
                    ...{
                        drawingQr: action.payload.qr,
                        placemark: action.payload.placemark
                    }
                };
            }
            return state;
        case QrActionsTypes.COMPLETE_EDIT_QR:
        case QrActionsTypes.COMPLETE_ADD_QR:
            if (state.placemark) {
                state.placemark.editor.stopDrawing();
            }
            return {
                ...state,
                ...{
                    drawingQr: null,
                    placemark: null,
                    event: null
                }
            };
        case MapModes.ADD_QR_CODES:
            return {
                ...state,
                ...{
                    drawingQr: {
                        id: -1,
                        name: '',
                        latitude: 0,
                        longitude: 0,
                    }
                }
            }
        case QrActionsTypes.UPDATE_QR:
            if (action.payload.map) {
                deleteItem(action.payload.map, 'qrId' , action.payload.qr.id);
                const newPlacemark = getQrCode(action.payload.qr);
                action.payload.map.geoObjects.add(newPlacemark);
                attachQrListeners(action.payload.qr, newPlacemark, action.payload.dispatch)
            }
            return {
                ...state,
                ...{
                    qrs: state.qrs.map((qr: IQr) => qr.id === action.payload.qr.id ? action.payload.qr : qr),
                }
            }
        case QrActionsTypes.CREATE_QR:
            if (action.payload.map) {
                deleteItem(action.payload.map, 'qrId' , -1);
                const newPlacemark = getQrCode(action.payload.qr);
                action.payload.map.geoObjects.add(newPlacemark);
                attachQrListeners(action.payload.qr, newPlacemark, action.payload.dispatch)
            }
            return {
                ...state,
                ...{
                    qrs: state.qrs.concat(action.payload.qr)
                }
            };
        case QrActionsTypes.DELETE_QR:
            if (action.payload.map) {
                deleteItem(action.payload.map, 'qrId' , action.payload.id);
            }
            return {
                ...state,
                ...{
                    qrs: state.qrs.filter((qr: IQr) => qr.id !== action.payload.id),
                    drawingQr: null,
                    placemark: null,
                }
            };
        default:
            return state
    }
}
