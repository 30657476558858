import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {buildMap} from '../App/data/map';
import {styled} from '@mui/material/styles';
import {IRootState} from '../App/reducers/store';
import {MapActionsTypes} from '../Map/interface/map';
import {TargetActionsTypes} from '../Target/interface/target';
import {BuildingActions} from '../Buildings/actions/building';
import useRoutePanelControl from '../Target/RoutePanelControl';
import {buildQr} from "../App/data/qrMap";
import {QrActions} from "../Qr/actions/qr";
import {IEntry} from "../Entry/interface/entry";
import {DepartmentActions} from '../Departments/actions/department';

const Map = styled('div')({
    width: '100%',
    height: '100vh',
    position: 'relative',
});

declare var ymaps: any;

interface YandexMapProps {
    isAdmin: boolean,
}

const YandexMap: React.FC<YandexMapProps> = ({ isAdmin }) => {
    const dispatch: any = useDispatch();
    const {map} = useSelector((state: IRootState) => state.map);
    const {qrs} = useSelector((state: IRootState) => state.qr);
    const {buildings} = useSelector((state: IRootState) => state.buildings);

    const init = useCallback(() => {
        const myMap = new ymaps.Map('map', {
            center: [55.683856, 37.759344],
            zoom: 17.95,
            controls: isAdmin ? [] : ['zoomControl', 'routePanelControl']
        }, {
            geocodeProvider: false
        });

        myMap.events.add('click', (event: any) => dispatch({type: MapActionsTypes.HANDLE_CLICK_MODE, payload: event}));

        dispatch({type: MapActionsTypes.SET_MAP, payload: myMap});
    }, [dispatch, isAdmin]);

    const getBuildings = async () => dispatch(BuildingActions.buildings());

    const getQrs = async () => dispatch(QrActions.qrs());

    const getDepartments = async () => dispatch(DepartmentActions.departments());

    const getData = async () => {
        await getQrs();
        await getBuildings();
        await getDepartments();
    }

    useEffect(() => {
        getData().then(() => {
                if (!map) {
                    ymaps.ready(init);
                }
            },
            () => {
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const targetTo = (entries: IEntry[]) => {
        dispatch({
            type: TargetActionsTypes.SET_TARGET_TO,
            payload: entries.map((entry: IEntry) => [entry.latitude, entry.longitude])
        })
    }

    useEffect(() => {
        if (map) {
            buildMap(map, buildings, targetTo, dispatch, isAdmin);
            buildQr(map, qrs, dispatch, isAdmin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map]);

    useRoutePanelControl(isAdmin);

    const setControlPopupWidth = useCallback(() => {
        if (map) {
            const ymapsControlPanelElement = document.querySelector(
                '.ymaps-2-1-79-control-popup'
            ) as HTMLElement;
            const ymapsCopyrightsElement = document.querySelector(
                '.ymaps-2-1-79-copyrights-pane'
            ) as HTMLElement;
            const ymapsZoomElement = document.querySelector(
                '.ymaps-2-1-79-zoom'
            ) as HTMLElement;
            if (ymapsControlPanelElement) {
                // ymapsControlPanelElement.style.marginTop = '80px';
                // ymapsControlPanelElement.style.marginLeft = '18px';
                ymapsControlPanelElement.style.visibility = 'hidden';
                ymapsCopyrightsElement.style.display = 'none';
                ymapsZoomElement.style.right = '2%';
                ymapsZoomElement.style.top = '40%';
                ymapsZoomElement.style.position = 'fixed';
            }
        }
    }, [map]);

    useEffect(() => {
        setControlPopupWidth();
    }, [setControlPopupWidth]);

    return <Map id="map"/>;
};

export default YandexMap;
