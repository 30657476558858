import {IEntry} from "../../Entry/interface/entry";
import {IBuildingDepartment} from "../../Departments/interface/department";

export interface IBuilding {
    id: number;
    name: string;
    polygon: [number, number][];
    entries: IEntry[];
    departments: IBuildingDepartment[];
}

export interface IBuildingInput extends Omit<IBuilding, 'id' | 'entries' | 'departments'> {
}

export interface IBuildingState {
    buildings: IBuilding[] | []
    drawingBuilding: IBuilding | null
    polygon: any,
    coordinates: any
}

export enum BuildingActionsTypes {
    FETCH_BUILDINGS = 'FETCH_BUILDINGS',
    FETCH_BUILDING = 'FETCH_BUILDING',
    CREATE_BUILDING = 'CREATE_BUILDING',
    ADD_BUILDING = 'ADD_BUILDING',
    CLEAR_ADD_STATE = 'CLEAR_ADD_STATE',
    EDIT_BUILDING = 'EDIT_BUILDING',
    COMPLETE_EDIT_BUILDING = 'COMPLETE_EDIT_BUILDING',
    DELETE_BUILDING = 'DELETE_BUILDING',
    UPDATE_BUILDING = 'UPDATE_BUILDING'
}