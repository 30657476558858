import {AlertActionsTypes} from "../../App/interface/alert"
import EntryServices from "../services/entry";
import {EntriesActionsTypes, IEntryInput} from "../interface/entry";
import {MapActionsTypes} from "../../Map/interface/map";

export const EntriesActions = {
    entries: () => (dispatch: any) => new Promise((resolve, reject) => EntryServices.entries().then(
        response => {
            dispatch({type: EntriesActionsTypes.FETCH_ENTRIES, payload: response})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    create: (data: IEntryInput, map: any) => (dispatch: any) => new Promise((resolve, reject) => EntryServices.create(data).then(
        response => {
            dispatch({
                type: EntriesActionsTypes.CREATE_ENTRY, payload: {
                    entry: response.data,
                    map: map,
                    dispatch: dispatch
                }
            })
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    update: (id: number, data: IEntryInput) => (dispatch: any) => new Promise((resolve, reject) => EntryServices.update(id, data).then(
        response => {
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
    delete: (id: number, map: any) => (dispatch: any) => new Promise((resolve, reject) => EntryServices.delete(id).then(
        response => {
            dispatch({
                type: EntriesActionsTypes.DELETE_ENTRY, payload: {
                    id: id,
                    map: map,
                    dispatch: dispatch
                }
            })
            dispatch({type: MapActionsTypes.SET_DEFAULT_MODE})
            resolve(response)
        },
        error => {
            dispatch({
                type: AlertActionsTypes.ERROR, payload: {
                    title: "Ошибка запроса",
                    message: error.message,
                }
            })
            reject(error)
        }
    )),
}
