import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {AccountActionsTypes} from "../Account/interface/account";


const Logout = () => {
	const dispatch: any = useDispatch();
	const navigate = useNavigate();

	const logout = async () => {
		localStorage.clear()
		await dispatch({type: AccountActionsTypes.CLEAR})
		navigate('/login')
	}

	useEffect(() => {
		logout()
	});


	return null;
};

export default Logout;