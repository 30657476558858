import {IBuilding} from '../../Buildings/interfaces/building';
import {GetPolygon} from '../helpers/polygon';
import {Dispatch} from "../reducers/store";
import {attachBuildingsListeners} from "../helpers/building";
import {attachEntriesListeners} from "../helpers/entry";
import {IEntry} from "../../Entry/interface/entry";

declare var ymaps: any;

const controlOptions = {
    multiRoute: true,
    allowSwitch: false,
    reverseGeocoding: true,
    mapStateAutoApply: true,
    routingMode: 'pedestrian',
    types: {masstransit: false, pedestrian: true, taxi: false},
};

export type EntriesCoord = {
    [id: number]: IEntry[]
};

export const getEntry = (entry: IEntry) => {
    return new ymaps.Placemark(
        [entry.latitude, entry.longitude],
        {
            iconCaption: `Вход`,
            entryId: entry.id,
            buildingId: entry.building_id
        },
        {
            preset: 'islands#blueCircleDotIconWithCaption',
            iconCaptionMaxWidth: '250',
        }
    );
}

const buildMap = (
    map: any,
    buildings: IBuilding[],
    callback: (coord: any) => void,
    dispatch: Dispatch,
    isAdmin: boolean
) => {
    const entriesCoord: EntriesCoord = {};

    buildings.forEach((building: IBuilding) => {
        entriesCoord[building.id] = building.entries;

        building.entries.forEach((entry: IEntry) => {
            const entryPlacemark = getEntry(entry);
            map.geoObjects.add(entryPlacemark);
            if(isAdmin){
                attachEntriesListeners(entry, entryPlacemark, dispatch);
            }
        });
    });

    const control = map.controls.get('routePanelControl');
    if (!isAdmin) { control.routePanel.options.set(controlOptions)};

    buildings.forEach((building: IBuilding) => {
        const polygon = GetPolygon(building, () => callback(entriesCoord[building.id]), isAdmin);
        map.geoObjects.add(polygon);
        if(isAdmin){
            attachBuildingsListeners(building, polygon, dispatch);
        }
    });
};

export {controlOptions, buildMap};
